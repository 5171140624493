import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AnimatedCursor from "react-animated-cursor";
import MouseParticles from "react-mouse-particles";
import ReactFluidAnimation from '@usertive/react-fluid-animation';

ReactDOM.render(
    <BrowserRouter>
      <App/>
      <ReactFluidAnimation  className="animationData"/>
     </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
