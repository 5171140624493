import React, { useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { NavLink } from 'react-router-dom';
import './contact.scss';
import Header from '../header';
import Footer from '../footer';
import contact from '../images/contact-us.png';
import msg from '../images/data-msg.png';
import call from '../images/data-call.png';

const Contact = () => {
    const [modal, setModal] = useState(false);
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        const formData = new FormData(form.current);
        const data = {
            name: formData.get('user_name'),
            email: formData.get('user_email'),
            phone_number: formData.get('user_mobile'),
            message: formData.get('message')
        };

        fetch('https://console.mine2.io/api/v1/contact', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(result => {
                console.log(result);
                setModal(true);
            })
            .catch(error => {
                console.log(error);
            });
    };
  return (
      <div>
          <Header/>
          <div className="container">
          <div className="banner-info">
                  <div className="content">
                  <div className="row">
                      <div className="col-sm-8">
                          <h1>Contact Us </h1>
                          <p>Reach out to us with any questions or inquiries. We're here to help and look forward to connect with you.</p>
                      </div>
                      <div className="col-sm-4">
                         <img src={contact}/>
                      </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="container">
              <div className="box-data">
                  <div className="inner-box">
                      <div className="row">
                          <div className="col-sm-6">
                              <div className="inner-info">
                                  <h4>Contact Us</h4>
                                  <p>Ask us anything and we'll help you find the answers.</p>
                                  <p>Response time: within 24 hours</p>
                                  <div className="contact-info"><img src={msg}/> support@mine2.io</div>
                                  <div className="contact-info"><img src={call}/>+91 98100 64859</div>
                                  <p className="maginn-data">Corporate Office: </p>
                                  <p className="margin-top">Delhi</p>
                              </div>
                          </div>
                          <div className="col-sm-6">
                              <div className="get-in-touch">
                                  <h5>Get in Touch</h5>
                                  <p>Feel free to drop us a line below!</p>
                                  <form ref={form} onSubmit={sendEmail}>
                                  <div className="form-group">
                                    <label for="Name">Name </label>
                                    <input required type="text" className="form-control" name="user_name"></input>
                                  </div>
                                  <div className="form-group">
                                    <label for="email-id">Email </label>
                                    <input required type="email" className="form-control" name="user_email"></input>
                                  </div>
                                  <div className="form-group">
                                    <label for="mobile">Phone Number </label>
                                    <input required type="number" className="form-control" name="user_mobile"></input>
                                  </div>
                                  <div className="form-group">
                                    <label for="message">Message </label>
                                    <textarea required className="form-control" name="message"></textarea>
                                  </div>
                                  <div class="text-center">
                                    <input class="btn btn-primary waves-effect waves-light" type="submit" onReset={form} value="Send Message"/>
                                    </div>
                                  </form>
                                  {modal && 
                                    <div className='thanksYou'>
                                        <div class="wrapper-2">
                                        <h1>Thank you !</h1>
                                        <p>Your submission has been received successfully. Our team is eager to assist you and will be in touch asap.</p>
                                        <NavLink className="nav-link" to="/"> <button class="go-home">
                                        go home
                                        </button>
                                        </NavLink>
                                        </div>
                                    </div>
                                  }
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <Footer/>
      </div>
    );
}

export default Contact;