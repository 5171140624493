import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './services.scss';
import Header from '../header';
import Footer from '../footer';
import about from '../images/about_us.png';
import ngright from '../images/ng-right.png';
import { Helmet } from "react-helmet";
import TestimonialsClientNew from '../testimonialClient';

const Services = () => {
    const [name, setName] = useState();
    const [fullName, setFullName] = useState();
    const inputEvent = (event) => {
      setName(event.target.value);
    };
    const onSubmit = () =>{
        setFullName(name);
    }
  return (
    <React.Fragment>
        <Helmet>
            <title>Digital Services for Your Business Success - MINE2.com</title>
            <meta name="description" content="Fuel your success online with our expert digital marketing solutions. Elevate your brand, drive traffic, and thrive in the digital landscape." />
            <link rel="canonical" href="https://www.MINE2.com" />
            <meta name="keywords" content="digital marketing near me, app creators near me, digital marketing service, digital marketing solutions, seo company, amazon marketing service,
                email marketing service, ppc agency, online digital marketing, seo optimization" />
            <meta property="og:title" content="Digital Services for Your Business Success - MINE2.com" />
            <meta property="og:description" content="Fuel your success online with our expert digital marketing solutions. Elevate your brand, drive traffic, and thrive in the digital landscape." />
            <meta property="og:image" content="https://mine2.io/" />
            <meta property="og:url" content="https://your-website.com/" ></meta>
            <meta name="twitter:title" content="Digital Services for Your Business Success - MINE2.com" />
            <meta name="twitter:description" content="Fuel your success online with our expert digital marketing solutions. Elevate your brand, drive traffic, and thrive in the digital landscape." />
            <meta name="twitter:url" content="https://mine2.io/" />
            <meta name="twitter:card" content="Maximize your digital impact with MINE2's SEO mastery"></meta>
        </Helmet>
      <div>
          <Header/>
          <div className="container">
          <div className="banner-info" style={{marginBottom:0}}>
                  <div className="content">
                  <div className="row">
                      <div className="col-sm-8">
                          <h1>Hacker statistics</h1>
                          <p>Cyber Security Services Provider</p>
                      </div>
                      <div className="col-sm-4">
                        <img src="../images/service.png" alt="service"/>
                      </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="solution">
                <div className="headingDataInfo">
                    <h2>Hacker statistics</h2>
                </div>
                <div className="container">
                    <div className='otrService'>
                     <TestimonialsClientNew/>
                    </div>
                </div>
            </div>
            <div className='ourServices'>
                <div className='container'>
                    <h5>OUR SERVICES</h5>
                    <p>We Provide best Cyber security Services </p>
                    <br/><br/>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="feature-box h-100 text-center px-4 py-5">
                            <div className="feature-box-icon"><img className="w-25" src="images/check-mark.svg" alt=""/></div>
                            <h3 className="feature-box-title">Prerequisites?</h3>
                            <p className="feature-box-desc">There are absolutely NO prerequisites for you to enroll for the most basic plan of Mine2. It will cover more than 50% of attack vectors and any organization can go along with it.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="feature-box h-100 text-center all-text-white bg-grad px-4 py-5 border-radius-3">
                            <div className="feature-box-icon"><img className="w-25" src="images/editor.svg" alt=""/></div>
                            <h3 className="feature-box-title">Why Mine2?</h3>
                            <p className="feature-box-desc">A firm established by hackers keeping the thought of known attack vectors into the picture, we know how it happens and what are the right places to keep the mines to prevent such breaches</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="feature-box h-100 text-center px-4 py-5">
                            <div className="feature-box-icon"><img className="w-25" src="images/envelope.svg" alt=""/></div>
                            <h3 className="feature-box-title">Work hinderance?</h3>
                            <p className="feature-box-desc">Our mines are designed in such a way that they do not hinder the normal processes of work. All the tasks and systems keep running in the same fashion as they do right now.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="trusted-data">
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="content">
                                    {/* <p>"We are the most trusted business partner
                                    for Consulting solution by our clients"</p> */}
                                    <p>Cyber security services</p>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="br-right">
                                        <img src={ngright}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>       
          <Footer/>
      </div>
      </React.Fragment>
    );
}

export default Services;