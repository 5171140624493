import React from 'react';
import './testimonialClient.scss';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function TestimonialsClient() {
  const options = {
    margin: 20,
    responsiveClass: true,
    nav: true,
    loop:true,
    dots: true,
    autoplay: false,
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
            autoplay: true,
        },
        400: {
            items: 1,
            autoplay: true,
            smartSpeed: 3000,
        },
        600: {
            items: 1,
            autoplay: true,
            smartSpeed: 3000,
        },
        700: {
            items: 1,
            autoplay: true,
            smartSpeed: 3000,
        },
        1000: {
            items: 3,

        }
    },
};
  return (
    <div className='owlDataclient'>
      <OwlCarousel className='owl-theme' items="1" loop margin={10}  dots nav autoplay {...options}>
          <div class='itemNew'>
          <div className='cardSectionInfo'>
                           <img alt="Email Marketing" src="images/services/email_market.jpg"/>
                           <h3>212 Days</h3> 
                           <p>The number of days it took for an organization to identify a Data Breach in 2021
                           </p>
                        </div>
          </div>
          <div class='itemNew'>
          <div className='cardSectionInfo'>
                           <img alt="Search Engine Optimization" src="images/services/vp.jpg"/>
                           <h3>4.24 Million USD</h3> 
                           <p>The average loss caused by a single Data Breach in 2022 </p>
                        </div>
          </div>
          <div class='itemNew'>
          <div className='cardSectionInfo'>
                           <img alt="Ecommerce" src="images/services/eCommerce_web.jpg"/>
                           <h3>27 Billion USD</h3> 
                           <p>The size of Zero Trust Model market in 2022 Hacker statistics</p>
                        </div>
          </div>
    </OwlCarousel>
   </div>
    ); 
 }

export default TestimonialsClient;