import React from 'react';
import { NavLink } from 'react-router-dom';
import './slider.scss';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function Slider() {
  return (
   <div className='outerMainOuter'>
      <div className='videoOuterInfo'>
        <div className="elementor-background-video-embed"></div>
        <iframe src="https://player.vimeo.com/video/923641745?muted=1&amp;autoplay=1&amp;loop=1&amp;background=1&amp;app_id=122963"  frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" title="videoplayback 1"></iframe>
      </div>
      <div className='contentCenter'>
        <h3>CYBER SECURITY</h3>
        <p>Protecting information from unauthorized access, disclosure, modification, or destruction.</p>
      </div>
   </div>
   //  <div className='container'>
   //  <OwlCarousel className='owl-theme main' items="1" loop margin={10}  dots autoplay>
   //  <div class='item'>
   //    <div class="container">
   //        <div class="carousel-caption text-left">
   //            <div class="row">
   //              <div class="col-sm-7">
   //                    <h2>Welcome to MINE2, Where Innovation Meets Practically.</h2>
   //                    <NavLink to="/contact"><button className='btn-primary'>Connect</button></NavLink>
   //              </div>
   //              <div class="col-sm-5">
   //                 <div className='innovation'>
   //                    <img src="./images/innovation_3.png" alt="Innovation"/>
   //                 </div>
   //              </div>
   //             </div>
   //        </div>
   //      </div>
   //  </div>
   //  <div class='item'>
   //    <div class="container">
   //        <div class="carousel-caption text-left">
   //            <div class="row">
   //              <div class="col-sm-7">
   //                    <h2>Let's take your journey digitally with us.</h2>
   //                    <NavLink to="/contact"><button className='btn-primary'>Connect</button></NavLink>
   //              </div>
   //              <div class="col-sm-5">
   //                 <div className='innovation'>
   //                    <img src="./images/digital.png" alt="Innovation"/>
   //                 </div>
   //              </div>
   //             </div>
   //        </div>
   //      </div>
   //  </div>
   //  <div class='item'>
   //    <div class="container">
   //        <div class="carousel-caption text-left">
   //            <div class="row">
   //              <div class="col-sm-7">
   //                    <h2>Transforming your ideas into realities.</h2>
   //                    <NavLink to="/contact"><button className='btn-primary'>Connect</button></NavLink>
   //              </div>
   //              <div class="col-sm-5">
   //                 <div className='innovation'>
   //                    <img src="./images/ideas.png" alt="Innovation"/>
   //                 </div>
   //              </div>
   //             </div>
   //        </div>
   //      </div>
   //  </div>
   //  <div class='item'>
   //    <div class="container">
   //        <div class="carousel-caption text-left">
   //            <div class="row">
   //              <div class="col-sm-7">
   //                    <h2>Let's explore the dynamic platform of MINE2.</h2>
   //                    <NavLink to="/contact"><button className='btn-primary'>Connect</button></NavLink>
   //              </div>
   //              <div class="col-sm-5">
   //                 <div className='innovation' style={{marginTop: 40}}>
   //                    <img src="./images/dynamic.png" alt="Innovation"/>
   //                 </div>
   //              </div>
   //             </div>
   //        </div>
   //      </div>
   //  </div>
   // </OwlCarousel>
   // </div>
    ); 
 }

export default Slider;