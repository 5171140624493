import React from 'react';
import { NavLink } from 'react-router-dom';
import absotule from '../images/absotule.png';
import feature from '../images/feature.png';
import security from '../images/security.png';
import ngright from '../images/ng-right.png';
import sme from '../images/SME.png';
import enter from '../images/enter.png';
import solution from '../images/solution.png';
import 'bootstrap/dist/css/bootstrap.css';
import './main.scss';
import Testimonials from '../testimonial';
import TestimonialsClient from '../testimonialClient';
import FluidAnimation from 'react-fluid-animation'

function Main() {
    return (
        <div>
            <div className="container marketing">
                <div className='outerTrustedPartner borderTop'>
                    <div className='contentInfoDataStay'>
                        <div className='contentsty'>
                            <h1>The Worlds First Digital Landmines</h1>
                            <p>Stay ahead of attackers trying to enter the premises and exfiltrate data </p>
                        </div>
                        <NavLink to="/contact"><button className='bookingInfo'>Book a Meeting</button></NavLink>
                    </div>
                </div>
                <div className='outerTrustedPartner box-shadow'>
                    <h2>Hacker statistics</h2>
                    <p></p>
                    <TestimonialsClient/>
                </div>
            </div>
            <div className='solution'>
                <div className='container'>
                <div className="headingDataInfo">
                        <h2>Our Approach</h2>
                        <p></p>
                    </div>
                    <div className='outerProcess'>
                        <div className="timeline">
                            <div className="outer">
                                <div className="cardNew">
                                <div className="info">
                                    <h3 className="title">01</h3>
                                    <p>A Mesh of Mines spread across your Infrastructure</p>
                                </div>
                                </div>
                                <div className="cardNew">
                                <div className="info">
                                    <h3 className="title">02</h3>
                                    <p>Attackers lured to step in on the mines. </p>
                                </div>
                                </div>
                                <div className="cardNew">
                                <div className="info">
                                    <h3 className="title">03</h3>
                                    <p>Alerts generated and captured by C&C Server</p>
                                </div>
                                </div>
                                <div className="cardNew">
                                <div className="info">
                                    <h3 className="title">04</h3>
                                    <p>Administrators alerted of the triggered mines, the very same minute</p>
                                </div>
                                </div>
                            </div>
                            </div>
                    </div>
                </div>
            </div>
            <div className="solution">
                <div className="headingDataInfo">
                    <span className="childHead"></span>
                    <h2>How We Distinguish From The <b>Industry?</b></h2>
                </div>
                <div className="container">
                    <div className='technologySection justify-content_space-around'>
                         <div className='circleZeroData'>
                            <div className='infodataCnt'>
                                <div className='cardNewSectionInfo'>
                                    <h3> Zero Dependencies</h3> 
                                    <p>There are absolutely zero dependencies needed to install Mines in your network.</p>
                                </div>
                                <div className='cardNewSectionInfo'>
                                    <h3>Zero Coding Skills</h3> 
                                    <p>There are absolutely no coding skills required to deploy any Mine. We have made it a piece of cake to grab by and throw it at your desirable place.</p>
                                </div>
                            </div>
                            <div className='CenterCircleInfo'>Zero</div>
                            <div className='infodataCnt'>
                                <div className='cardNewSectionInfo'>
                                    <h3>Zero Monitoring</h3> 
                                    <p>The Innocent Mines reside without ever collecting any data. Their sole purpose is to alert as soon as someone steps on them.</p>
                                </div>
                                    <div className='cardNewSectionInfo'>
                                    <h3>Zero Bandwidth</h3> 
                                    <p>Even if thousands of mines are placed, No bandwidth of RAM or Network is utilised.</p>
                                </div>
                            </div>
                         </div>
                    </div>
                </div>
            </div>
            <div className='solution'>
                <div className='container'>
                <div className="headingDataInfo">
                        <h2>All-in One Solution</h2>
                        <p>Our experts are providing and planning 24/7 technical support services with product and security consultation
                        Bring down the intrusion detection time from few months to a few minutes</p>
                    </div>
                <ul className="process">
                        <li className="process__item">
                            <span className="process__number">01</span>
                            <span className="process__title">Ensure rogue internal users are caught in time</span>
                        </li>

                        <li className="process__item">
                            <span className="process__number">02</span>
                            <span className="process__title">Prevent Social Engineering Attacks</span>
                        </li>

                        <li className="process__item">
                            <span className="process__number">03</span>
                            <span className="process__title">Help in implementing Zero Trust Architecture</span>
                        </li>

                        <li className="process__item">
                            <span className="process__number">04</span>
                            <span className="process__title">Get attacker Identifiable information</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='ourServices'>
                <div className='container'>
                    <h5>OUR SERVICES</h5>
                    <p>We Provide best Cyber security Services </p>
                    <br/><br/>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="feature-box h-100 text-center px-4 py-5">
                            <div className="feature-box-icon"><img className="w-25" src="images/check-mark.svg" alt=""/></div>
                            <h3 className="feature-box-title">Prerequisites?</h3>
                            <p className="feature-box-desc">There are absolutely NO prerequisites for you to enroll for the most basic plan of Mine2. It will cover more than 50% of attack vectors and any organization can go along with it.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="feature-box h-100 text-center all-text-white bg-grad px-4 py-5 border-radius-3">
                            <div className="feature-box-icon"><img className="w-25" src="images/editor.svg" alt=""/></div>
                            <h3 className="feature-box-title">Why Mine2?</h3>
                            <p className="feature-box-desc">A firm established by hackers keeping the thought of known attack vectors into the picture, we know how it happens and what are the right places to keep the mines to prevent such breaches</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="feature-box h-100 text-center px-4 py-5">
                            <div className="feature-box-icon"><img className="w-25" src="images/envelope.svg" alt=""/></div>
                            <h3 className="feature-box-title">Work hinderance?</h3>
                            <p className="feature-box-desc">Our mines are designed in such a way that they do not hinder the normal processes of work. All the tasks and systems keep running in the same fashion as they do right now.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container marketing">
            </div>
            {/*<div className="company-url clientdata">*/}
            {/*    <div className="headingDataInfo"><h2>Our  <b>Distinguished Clients </b></h2></div>*/}
            {/*    <div className="container">*/}
            {/*    <div className="clientOuter">*/}
            {/*    <span className="imglogo">*/}
            {/*        <img style={{width: 55}} src="./Logo/headlock.jpeg" alt="client"/>*/}
            {/*    </span>*/}
            {/*    <span className="imglogo">*/}
            {/*        <img style={{width: 200}} src="./Logo/amit.png" alt="client"/>*/}
            {/*    </span>*/}
            {/*    <span className="imglogo">*/}
            {/*        <img style={{width: 130}} src="./Logo/sai.png" alt="client"/>*/}
            {/*    </span>*/}
            {/*    <span className="imglogo">*/}
            {/*        <img style={{width: 111}}  src="./Logo/nexus.png" alt="client"/>*/}
            {/*    </span>*/}
            {/*    <span className="imglogo">*/}
            {/*        <img src="https://globalpda.com/wp-content/uploads/2020/05/logo.png" alt="client"/>*/}
            {/*    </span>*/}
            {/*    <span className="imglogo">*/}
            {/*        <img src="./Logo/mine2.png" alt="client"/>*/}
            {/*    </span>*/}
            {/*    /!* <span className="imglogo rocket">*/}
            {/*        <img src="https://rocketfuel.inc/wp-content/uploads/2022/10/RocketFuelLogoR.svg" alt="client"/>*/}
            {/*    </span> *!/*/}
            {/*    <span className="imglogo">*/}
            {/*        <img src="./Logo/senger.png" alt="client"/>*/}
            {/*    </span>*/}
            {/*    <span className="imglogo">*/}
            {/*        <img src="./Logo/baba_saheb.png" alt="client"/>*/}
            {/*    </span>*/}
            {/*    <span className="imglogo">*/}
            {/*        <img src="./Logo/yoginis.png" alt="client"/>*/}
            {/*    </span>*/}
            {/*    <span className="imglogo">*/}
            {/*        <img src="./Logo/gcs.png" alt="client"/>*/}
            {/*    </span>*/}
            {/*    <span className="imglogo">*/}
            {/*        <img src="./Logo/globalkidsolympiads_logo.png" alt="client"/>*/}
            {/*    </span>*/}
            {/*    <span className="imglogo">*/}
            {/*        <img src="./Logo/kaushik.png" alt="client"/>*/}
            {/*    </span>*/}
            {/*    </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
                    {/*<div className="testimonialsInfo">*/}
                    {/*    <div className="container">*/}
                    {/*        <div className="row">*/}
                    {/*            <div className="col-sm-7">*/}
                    {/*                <div className='contentTst'>*/}
                    {/*                    <h5>Our Customers</h5>*/}
                    {/*                    <p>Read our success stories to see how we've helped businesses achieve top search engine rankings and significant ROI through expert digital marketing strategies.</p>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className="col-sm-5">*/}
                    {/*                  <div className='sliderTest'>*/}
                    {/*                      <Testimonials/>*/}
                    {/*                  </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
>>>>>>> 3e5935a (rm testimonials)
                    <div className='footerSubs'>
                        <div className='container'>
                            <div className='flexInfo'>
                                <h3>Want To Speak With Our Solution Experts?</h3>
                                <NavLink to="/contact"><button className='bookingInfo'>Book a Meeting</button></NavLink>
                            </div>
                        </div>
                    </div>
    </div>
      ); 
   }
  
  export default Main;