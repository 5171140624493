import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './home.scss';
import Header from '../header';
import Slider from '../slider';
import Main from '../main';
import Footer from '../footer';
import ReactFluidAnimation from '@usertive/react-fluid-animation';

const Home = () => {
  return (
      <div className='main'>
          <Header/>
          <Slider/>
          <Main/>
          <Footer/>
          <ReactFluidAnimation  className="animationData"/>
      </div>
    );
}

export default Home;