import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './about.scss';
import Header from '../header';
import Footer from '../footer';
import about from '../images/about_us.png';
import leftContant from '../images/left-contant.png';

const About = () => {
  return (
      <div>
          <Header/>
          <div className="container">
          <div className="banner-info">
                  <div className="content">
                  <div className="row">
                      <div className="col-sm-8">
                          <h1>We are MINE2</h1>
                          <p>At MINE2, we ignite innovation. With a blend of expertise, creativity, and commitment, we provide tailored IT solutions that drive success.</p>                            
                      </div>
                      <div className="col-sm-4">
                         <img src="../images/bg.png" alt="about"/>
                      </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="container marketing">
          <div class="row featurette dataInfoCnt">
          <div class="col-md-5">
          <img src="../images/aboutInr.png" alt="about"/>
              </div>
              <div class="col-md-7">
              <h1>Who We Are?</h1>
              <p>We strive to build long-lasting partnerships with our clients, focusing on their success and delivering exceptional customer service, we aim to be at the forefront of the IT industry, constantly pushing the boundaries of what is possible and driving digital transformation for our clients and We envision a future where businesses can fully harness the power of technology to achieve their goals. </p>
              <p>We are dedicated to making that vision a reality through our innovative solutions and unwavering commitment to our clients.</p>
              </div>
              </div>
          </div>
          <div className="container marketing">
          <div class="row featurette">
              <div class="col-md-12 dataInfoCnt">
                <h6>Meet the MINE2's Founder</h6>
                <p>Anurag Goyal is the Esteemed Founder of MINE2, an innovative firm specializing in IT services, consulting, and product engineering, dedicated to creating tailored solutions and cutting-edge IT products.</p>
                <p>Under the inspiring leadership of Mrs. Pandey, MINE2 has metamorphosed from a modest startup in India's bustling tech landscape into an internationally recognized beacon of digital transformation and engineering services</p>
                 <p>In today's rapidly evolving technological landscape, our mission at MINE2 is more vital than ever, to empower businesses to reach their full potential through innovative IT solutions.</p>
                <p>Since our inception in 2021, we have dedicated ourselves to providing our clients with cutting-edge strategies, tailored to their unique needs and objectives. Our team of experts, drawn from diverse backgrounds in technology and business, works hand-in-hand with our clients, guiding them through every step of their digital transformation journey.</p>
                <p>Our commitment to excellence goes beyond merely delivering top-notch IT consulting services. We believe in forging lasting relationships grounded in trust and collaboration. By understanding the nuances of your business, we craft bespoke solutions that drive efficiency, enhance security, and foster growth.</p>
                <p>The future is brimming with opportunities, and we at MINE2 are excited to explore new horizons, innovate, and continue our pursuit of excellence. Your success is our success, and we invite you to join us on this exhilarating journey.</p>
                <p>Thank you for considering MINE2. Together, we will shape the future.</p>
              </div>
              </div>
          </div>
          <Footer/>
      </div>
    );
}

export default About;