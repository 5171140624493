import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com';
import 'bootstrap/dist/css/bootstrap.css';
import './jobDescription.scss';
import Header from '../header';
import Footer from '../footer';
import career from '../images/career.png';
import leftCareer from '../images/leftCareer.png';
import rightCareer from '../images/rightCareer.png';
import JobFilter from '../jobFilter';
import { NavLink } from 'react-router-dom';
import { Alert } from 'bootstrap';
import TestimonialsEmployee from '../testimonialEmployee';

const Description = () => {
  const [modal, setModal] = useState(false);
  const form = useRef();

const sendEmail = (e) => {
  e.preventDefault();

  emailjs.sendForm('service_wpuo9ix', 'template_m9qyr5e', form.current, 'Qq6Y5iaRh_iyI0Zbr')
    .then((result) => {
        console.log(result.text);
          setModal(true)
    }, (error) => {
        console.log(error.text);
    });
};
  return (
      <div>
          <Header/>
          <div className="container">
          <div className="banner-info bgdataUpdate">
              
                  <div className="content">
                  <div className="row">
                      <div className="col-sm-8">
                          <h1>E-Commerce Specialist: Amazon, Shopify & Product Development</h1>
                          <p>At DELHI NCR (Remote) INDIA</p>
                      </div>
                      <div className="col-sm-4">
                         <img src='/images/description1.png'/>
                      </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="outerDataInfoJob">
              <div className="container">
                  <div className="content">
                    <div className="row">
                        <div className="col-sm-8">
                          <h6>About the Role:</h6>
                          <p>We're in search of a dynamic E-Commerce Specialist proficient in Amazon, Shopify, and product development. Join our team to lead e-commerce operations, shape marketing tactics, and guide product development for our detergents and laundry products.</p>
                          <h6>Daily Responsibilities:</h6>
                          <p>- Inventory Management: Monitor and adjust stock levels across all sales channels.</p>
                          <p>- Supplier Relations: Serve as the primary liaison for suppliers; oversee orders, product development, and pricing discussions.</p>
                          <p>- Customer Support: Handle customer inquiries via email, chat, and phone.</p>
                          <h6>Weekly Initiatives:</h6>
                          <p>- Sales Reporting: Deliver weekly sales metrics reports.</p>
                          <p>- Marketing Collaboration: Partner with Marketing on ongoing and upcoming campaigns.</p>
                          <p>- Content Coordination: Collaborate with the content team for product descriptions and blog posts.</p>
                          <h6>Amazon Platform Management:</h6>
                          <p>- FBA Shipments: Oversee FBA deliveries, documentation, and compliance.</p>
                          <p><b>Inventory:</b> Forecast needs based on sales data; maintain optimal stock levels.</p>
                          <p><b>Replenishment:</b> Monitor inventory turnover; restock considering sales spikes and logistics.</p>
                          <p><b>Shipment Coordination:</b> Collaborate with suppliers and Amazon centers; resolve discrepancies.</p>
                          <p><b>Customer Interaction:</b> Respond to customer messages.</p>
                          <h6>Audit & Records:</h6>
                          <p>Weekly audits for FBA and bottler shipments.</p>
                          <p>Maintain audit records; oversee FBA refunds.</p>
                          <h6>Review Management:</h6>
                          <p>Monitor customer and seller review dashboards.</p>
                          <p>Address and dispute reviews; highlight standout feedback.</p>
                          <h6>Content Management:</h6>
                          <p>Update product details and visuals.</p>
                          <p>Optimize A+ content, and refresh Amazon Store pages.</p>
                          <h6>Agency Collaboration:</h6>
                          <p>Lead bi-weekly meetings with Amazon agency.</p>
                          <p>Ensure brand integrity on Amazon; manage Amazon Posts.</p>
                          <p>Track customer engagement metrics.</p>
                          <p>Coordinate with Amazon agency and Marketing team on promotions.</p>
                          <h6>Shopify Platform Management:</h6>
                          <p><b>Page Optimization:</b> Update product and collection pages with pertinent details and visuals.</p>
                          <p><b>Design & Promotion:</b> Rotate hero images frequently and work with Marketing on design improvements and promotional consistency.</p>
                          <h6>Product Development:</h6>
                          <p><b>Innovation:</b> Spearhead the creation of new cleaning products.</p>
                          <p>Market Analysis: Share frequent market research and competitor insights.</p>
                          <p>Design Collaboration: Partner with Marketing on packaging design, messaging, and promo inserts.</p>
                          <p>Supplier Communication: Engage with bottlers and manufacturers on pricing, formulation, and packaging.</p>
                          <h6>Qualifications & Skills:</h6>
                          <p><b>Platform Expertise:</b> Skilled in Amazon Seller Central, Shopify, and Walmart.</p>
                          <p><b>D2C Experience:</b> Proven success in Direct-to-Consumer marketing.</p>
                          <p><b>Technical Insight:</b> Understanding of chemistry or chemical engineering concepts is a plus.</p>
                        </div>
                        <div className="col-sm-4">
                          <div className="get-in-touchJob">
                                    <h5>Get in Touch</h5>
                                    <p>Feel free to drop us a line below!</p>
                                    <form ref={form} onSubmit={sendEmail}>
                                    <div className="form-group">
                                      <label for="Name">Name </label>
                                      <input required type="text" className="form-control" name="user_name"></input>
                                    </div>
                                    <div className="form-group">
                                      <label for="phone-id">Phone number </label>
                                      <input required type="number" className="form-control" name="user_phone"></input>
                                    </div>
                                    <div className="form-group">
                                      <label for="email-id">Email </label>
                                      <input required type="email" className="form-control" name="user_email"></input>
                                    </div>
                                    <div className="form-group">
                                       <label for="my-file">Attach CV: </label>
                                       <input required className="form-control" type="file" name="my_file"/> 
                                    </div>
                                    <div className="form-group">
                                      <label for="message">Message </label>
                                      <textarea required className="form-control" name="message"></textarea>
                                    </div>
                                    <div class="text-center mt-10">
                                      <input class="btn btn-primary waves-effect waves-light" type="submit" onReset={form} value="Submit aaplication"/>
                                      </div>
                                    </form>
                                    {modal && 
                                      <div className='thanksYou'>
                                          <div class="wrapper-2">
                                          <h1>Thank you !</h1>
                                          <p>Your submission has been received successfully. Our team is eager to assist you and will be in touch asap.</p>
                                          <NavLink className="nav-link" to="/"> <button class="go-home">
                                          go home
                                          </button>
                                          </NavLink>
                                          </div>
                                      </div>
                                    }
                                </div>
                          </div>
                    </div>
                 </div>
              </div>
          </div>
          <Footer/>
      </div>
    );
}

export default Description;