import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-sticky-header/styles.css';
import StickyHeader from 'react-sticky-header';
import './header.scss';
import { Helmet } from "react-helmet";
const Header = () => {
    const schema = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "MINE2 Technologies Pvt Ltd",
        "alternateName": "MINE2",
        "url": "https://www.MINE2.com",
        "logo": "https://mine2.io/images/MINE2Logo.png",
        "sameAs": [
            "https://www.facebook.com/people/MINE2/100069623461291/",
            "https://www.instagram.com/MINE2_inc/",
            "https://www.linkedin.com/company/MINE2-technologies/",
            ""
        ]
    }
  return (
    <React.Fragment>
        <Helmet>
            <title>Cyber security services</title>
            <meta name="description" content="Elevate your brand online with MINE2's expert digital marketing and SEO services. Drive targeted traffic, boost visibility, and achieve top search engine rankings. Let's transform your digital success together!" />
            <link rel="canonical" href="https://www.MINE2.com" />
            <meta name="keywords" content="digital marketing in Noida, digital marketing company near me, seo company near me, digital marketing service, digital marketing, seo company, digital marketing company,
                email marketing service, ppc agency, online digital marketing, digital marketing projects, graphic designers near me, website  development company, web development internship, photo edit karne wala, advertising agencies near me " />
            <meta property="og:title" content="Cyber security services" />
            <meta property="og:description" content="Elevate your brand online with MINE2's expert digital marketing and SEO services. Drive targeted traffic, boost visibility, and achieve top search engine rankings. Let's transform your digital success together!" />
            <meta property="og:image" content="https://mine2.io/" />
            <meta property="og:url" content="https://your-website.com/" ></meta>
            <meta name="twitter:title" content="Cyber security services" />
            <meta name="twitter:description" content="Elevate your brand online with MINE2's expert digital marketing and SEO services. Drive targeted traffic, boost visibility, and achieve top search engine rankings. Let's transform your digital success together!" />
            <meta name="twitter:url" content="https://mine2.io/" />
            <meta name="twitter:card" content="Maximize your digital impact with MINE2's SEO mastery"></meta>
            <script type="application/ld+json">
                {JSON.stringify(schema)}
            </script>
            
        </Helmet>
    <StickyHeader 
    header={
        
    <header className="header-top">
        <nav id="navbar_top" className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container">
        <NavLink  className="navbar-brand" to="/"><img src="/images/logo.png" className="App-logo" alt="logo" /></NavLink>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ml-auto action-buttons">
                <li class="nav-item">
                <NavLink exact activeClassName="active" className="nav-link" to="/">Home <span class="sr-only">(current)</span></NavLink>
                </li>
                <li class="nav-item dropdown">
                    <NavLink activeClassName="active" className="nav-link" to="/services">Services</NavLink>
                    {/* <ul class="dropdown-menu">
                        <li><NavLink className="dropdown-item" to="/services">Our Services</NavLink></li>
                        <li><NavLink className="dropdown-item" to="/seo-digital-marketing/">SEO & Digital Marketing</NavLink></li>
                        <li><NavLink className="dropdown-item" to="/website-&-applications">Website & Applications</NavLink></li>
                        <li><NavLink className="dropdown-item" to="/our-skillsets">Our Skillsets</NavLink></li>
                    </ul> */}
                </li>
                <li class="nav-item">
                    <div><a className='nav-link' href="https://console.mine2.io/sign-in" target='_blank'>Product</a></div>
                </li>
                <li class="nav-item">
                    <NavLink activeClassName="active" className="nav-link" to="/about">About Us</NavLink>
                </li>
                <li class="nav-item dropdown">
                    <a className='nav-link' href='javascript:void(0)'> Career <span style={{position:'relative', top:0}}><i className="fa fa-angle-down" aria-hidden="true"></i></span></a>
                    <ul class="dropdown-menu">
                        <li><NavLink className="dropdown-item" to="/career">Current Positions</NavLink></li>
                        <li><NavLink className="dropdown-item" to="/internship-program">Internship Program</NavLink></li>
                    </ul>
                </li>
                    <li class="nav-item">
                    <NavLink activeClassName="active" className="nav-link" to="/contact">Contact Us</NavLink>
                </li>
                </ul>
            </div>
        </div>
        </nav>
    </header>
    } >
     </StickyHeader>
     </React.Fragment>
  );
}

export default Header;
